import styled from "styled-components"

export const Dropdown = styled.select`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  appearance: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuNDE0MjksMC4yODU3MTYgTDAuMjg1NzE2LDEuNDE0MjkgTDgsOC4yODU3MSBMMTUuNzE0MywxLjQxNDI5IEwxNC41ODU3LDAuMjg1NzE2IEw4LDYuODU3MTYgTDEuNDE0MjksMC4yODU3MTYgWiIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==")
    no-repeat right 1rem center;
`
