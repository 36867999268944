import React, { useCallback, useMemo, useState } from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GalleryHeader from "@components/klips/gallery-header"
import Div from "@components/elements/div"
import Image from "@components/image"
import Grid from "@components/elements/grid"
import { useTheme } from "styled-components"
import Flex from "@components/elements/flex"
import { Link } from "gatsby"
import Span from "@components/elements/span"
import Heading from "@components/elements/heading"
import Paragraph from "@components/elements/paragraph"
import { Dropdown } from "@components/elements/dropdown"
import { KlipsEOPCta } from "@components/cta/eop/klips"

const Partner = ({ partner }) => {
  const { color, shadow } = useTheme()
  return (
    <Flex
      as={Link}
      boxShadow={shadow.soft}
      borderRadius="1rem"
      key={partner.slug}
      alignItems="center"
      margin="0 0 auto 0"
      to={`/partners/${partner.slug}`}
    >
      <Div padding="2rem 0">
        <Image
          file={partner.logo}
          width={150}
          height={150}
          objectFit="contain"
        />
      </Div>
      <Div
        width="100%"
        padding="1rem"
        borderRadius="0 0 1rem 1rem"
        background={color.indigo100}
        height="100%"
        lineHeight={1.5}
      >
        <Span center fontWeight={600}>
          {partner.name}
        </Span>
      </Div>
    </Flex>
  )
}

Partner.propTypes = {
  partner: PropTypes.object.isRequired,
}

const Partners = ({ data, pageContext }) => {
  const partners = data.allPartners.edges
  const [selectedCountry, setSelectedCountry] = useState("all")
  const [selectedLanguage, setSelectedLanguage] = useState("all")
  const [selectedExpertise, setSelectedExpertise] = useState("all")

  const handleCountryChange = event => {
    setSelectedCountry(event.target.value)
  }

  const handleLanguageChange = event => {
    setSelectedLanguage(event.target.value)
  }

  const handleExpertiseChange = event => {
    setSelectedExpertise(event.target.value)
  }

  const certifiedPartners = useMemo(() =>
    partners.filter(({ node: partner }) => partner.certified)
  ,[partners])

  const europePartners = useMemo(() =>
    partners.filter(({ node: partner }) => partner.region?.includes("Europe"))
  ,[partners])

  const americasPartners = useMemo(() =>
    partners.filter(({ node: partner }) => partner.region?.includes("Americas"))
  ,[partners])

  const asiaPartners = useMemo(() =>
    partners.filter(({ node: partner }) =>
      partner.region?.includes("Asia-Pacific")
    )
  ,[partners])

  const languages = useMemo(() => {
    return Array.from(
      new Set(partners.flatMap(({ node: partner }) => partner.languages))
    ).sort()
  }, [partners])

  const expertise = useMemo(() => {
    return Array.from(
      new Set(partners.flatMap(({ node: partner }) => partner.expertise))
    ).sort()
  }, [partners])

  const countries = useMemo(() => {
    return Array.from(
      new Set(partners.flatMap(({ node: partner }) => partner.country))
    ).sort()
  }, [partners])

  const filterPartners = useCallback(partner => {
    const matchesCountry =
      selectedCountry === "all" || partner.country === selectedCountry

    const matchesLanguage =
      selectedLanguage === "all" ||
      (partner.languages && partner.languages.includes(selectedLanguage))

    const matchesExpertise =
      selectedExpertise === "all" ||
      (partner.expertise && partner.expertise.includes(selectedExpertise))

    return matchesCountry && matchesLanguage && matchesExpertise
  }, [selectedCountry, selectedLanguage, selectedExpertise])

  const filteredPartners = useMemo(() => {
    if (
      selectedCountry === "all" &&
      selectedLanguage === "all" &&
      selectedExpertise === "all"
    ) {
      return []
    }
    return partners.filter(({ node: partner }) => filterPartners(partner))
  }, [partners, selectedCountry, selectedLanguage, selectedExpertise, filterPartners])

  const { shadow } = useTheme()

  return (
    <Layout
      seo={{
        title: "Partners",
        description: `Partners with us to help you succeed with data.`,
      }}
      fullWidth={true}
      canonical={pageContext.slug}
    >
      <GalleryHeader
        name={"Partners Directory"}
        cta="Want to try it yourself?"
        subtitle={`Find a partner near you to help you succeed with data.`}
        shadow={false}
        links={[{ text: "Partners" }]}
        image={data.image}
      />
      <Grid width="100%" container gap="3rem" columns="2fr 4fr" columnsMd="1fr" margin="0 auto 4rem auto">
        <Div
          height="min-content"
          background="white"
          padding="2rem"
          borderRadius="1rem"
          boxShadow={shadow.soft}
        >
          <Heading as="h4" margin="0 0 1rem">
            Filter partners by
          </Heading>
          <Flex>
            <Span>Country</Span>
            <Dropdown
              style={{ marginBottom: "1rem" }}
              onChange={handleCountryChange}
              options={countries}
            >
              <option value="all">All</option>
              {countries.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Dropdown>
            <Span>Language</Span>
            <Dropdown
              style={{ marginBottom: "1rem" }}
              onChange={handleLanguageChange}
            >
              <option value="all">All</option>
              {languages.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Dropdown>
            <Span>Expertise</Span>
            <Dropdown onChange={handleExpertiseChange}>
              <option value="all">All</option>
              {expertise.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Dropdown>
          </Flex>
        </Div>
        {filteredPartners.length > 0 ? (
          <Grid
            gap="1.5rem"
            columns="repeat(3, 1fr)"
            columnsSm="repeat(2, 1fr)"
          >
            {filteredPartners.map(({ node: partner }) => (
              <Partner key={partner.slug} partner={partner} />
            ))}
          </Grid>
        ) : (
          <Div>
            <Heading as="h2" margin="0 0 1rem">
              Certified Partners
            </Heading>
            <Paragraph margin="0 0 2rem">
              These organizations employ one or more Klipfolio Certified
              Experts: individuals that maintain the highest level of knowledge
              around planning, deploying, and managing Klipfolio dashboards.
            </Paragraph>
            <Grid
              margin="0 0 4rem"
              gap="1.5rem"
              columns="repeat(3, 1fr)"
              columnsSm="repeat(2, 1fr)"
            >
              {certifiedPartners.map(({ node: partner }) => (
                <Partner key={partner.slug} partner={partner} />
              ))}
            </Grid>
            <Heading as="h2" margin="0 0 2rem">
              Americas Partners
            </Heading>
            <Grid
              margin="0 0 4rem"
              gap="1.5rem"
              columns="repeat(3, 1fr)"
              columnsSm="repeat(2, 1fr)"
            >
              {americasPartners.map(({ node: partner }) => (
                <Partner key={`america-${partner.slug}`} partner={partner} />
              ))}
            </Grid>
            <Heading as="h2" margin="0 0 2rem">
              Europe Partners
            </Heading>
            <Grid
              margin="0 0 4rem"
              gap="1.5rem"
              columns="repeat(3, 1fr)"
              columnsSm="repeat(2, 1fr)"
            >
              {europePartners.map(({ node: partner }) => (
                <Partner key={`europe-${partner.slug}`} partner={partner} />
              ))}
            </Grid>
            <Heading as="h2" margin="0 0 2rem">
              Asia-Pacific Partners
            </Heading>
            <Grid
              gap="1.5rem"
              columns="repeat(3, 1fr)"
              columnsSm="repeat(2, 1fr)"
            >
              {asiaPartners.map(({ node: partner }) => (
                <Partner key={`asia-${partner.slug}`} partner={partner} />
              ))}
            </Grid>
          </Div>
        )}
      </Grid>
      <KlipsEOPCta />
    </Layout>
  )
}

Partners.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default Partners

export const pageQuery = graphql`
  query PartnersDirectory {
    image: directusFiles(
      directus_id: { eq: "d4de8d11-ebca-4567-ab99-6616433da3c0" }
    ) {
      ...imageData
    }
    allPartners(
      sort: { fields: name, order: ASC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          certified
          country
          languages
          name
          logo {
            ...imageData
          }
          global
          expertise
          region
          slug
        }
      }
    }
  }
`
